<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">
        {{ $t("message.errorLoadingData") }}
      </h4>
      <div class="alert-body">
        {{ $t("message.user.errorUser") }}
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
          {{ $t("message.user.listOfUser") }}
        </b-link>
        {{ $t("message.user.forOtherUser") }}
      </div>
    </b-alert>

    <template v-if="userData">
      <div>
        <b-card>
          <h2 class="mb-3">{{ $t("message.user.editUser") }}</h2>
          <b-row>
            <b-col md="3">
              <b-form-group label="Permissão " label-for="systemRole">
                <template v-slot:label>
                  {{ $t("message.user.permission") }}
                </template>
                <v-select
                  v-model="userData.systemRole"
                  :reduce="(val) => val.value"
                  :options="typeOptions"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Grupo de acesso" label-for="userGroup">
                <template v-slot:label>
                  {{ $t("message.user.accessGroup") }}
                </template>

                <v-select
                  v-model="userData.userGroup"
                  :options="groupOptions"
                  :reduce="(val) => val.value"
                />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label-for="userGroup">
                <template v-slot:label>
                  {{ $t("sessionTime") }}
                </template>

                <b-form-input v-model="userData.sessionTime" type="number" />
              </b-form-group>
            </b-col>

            <b-col md="1">
              <b-form-group label="Active" label-for="userGroup">
                <template v-slot:label>
                  {{ $t("message.user.active") }}
                </template>
                <b-form-checkbox
                  style="margin-left: 7px; margin-top: 12px"
                  v-model="userData.enabled"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col> 
            
            <b-col md="2">
              <b-form-group label="Active" label-for="userGroup">
                <template v-slot:label>
                  {{ $t("message.list.activeTimeSheet") }}
                </template>
                <b-form-checkbox
                  style="margin-left: 7px; margin-top: 12px"
                  v-model="userData.timeSheet"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col>    
            
            <b-col md="2">
              <b-form-group label="Active" label-for="userGroup">
                <template v-slot:label>
                  Bater ponto
                </template>
                <b-form-checkbox
                  style="margin-left: 7px; margin-top: 12px"
                  v-model="userData.point"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col>                  
          </b-row>
        </b-card>
      </div>
    </template>

    <div class="text-right">
      <b-button
        @click="updateUser()"
        variant="primary"
        :to="{ name: 'apps-users-list' }"
      >
        {{ $t("message.buttons.save") }}
      </b-button>
    </div>
  </component>
</template>

<style>
.mt {
  margin-top: -0.8em;
}
</style>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormSelect,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormSelect,
    vSelect,
  },

  data() {
    return {
      //Select
      groupOptions: [],

      userData: [],

      typeOptions: [
        { label: "Administrador", value: "ROLE_ADMIN" },
        { label: "Usuário", value: "ROLE_USER" },
      ],

      userDataObj: store.state.user.userData,
    };
  },

  async created() {
    await this.getData();

    var groupList = [];
    await axios
      .get(`${URL_API}userGroup`, { headers: getHeader(this.userDataObj) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const description = response.data[i].description;
          const accessControlId = response.data[i].accessControlId;
          groupList.push(description);
          this.groupOptions.push({
            label: description,
            value: accessControlId,
          });
        }
      });
  },

  methods: {
    async getData() {
      await axios({
        method: "get",
        url: `${URL_API}user/${router.currentRoute.params.id}`,
        headers: getHeader(this.userDataObj),
      }).then((response) => {
        this.userData = response.data;
      });
    },

    updateUser() {
      var updateData = {};

      this.userData.systemRole = "ROLE_USER";
      
      updateData = {
        id: this.userData.id,
        systemRole: this.userData.systemRole,
        userGroup: this.userData.userGroup,
        enabled: this.userData.enabled,
        sessionTime: this.userData.sessionTime,
        timeSheet: this.userData.timeSheet,
        point: this.userData.point,
      };

      axios({
        method: "put",
        url: `${URL_API}user/edit/${this.userData.id}`,
        headers: getHeader(this.userDataObj),
        data: updateData,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.saved"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$i18n.t("message.toast.updated"),
          },
        });
      });
    },
  },
};
</script>

<style></style>
